
import { defineComponent, onMounted, ref } from 'vue'
import MainSearch from '@/components/main-header/src/main-search.vue'
import { GetRoomProductList, AddGoods } from '@/service/main/main'
import { HyTable } from '@/base-ui/table/index'

import tableItems from '../config/tableItems-config'
import Exhibition from './Exhibition.vue'

export default defineComponent({
  components: { MainSearch, HyTable, Exhibition },
  props: {
    roomid: {
      type: String,
    },
    live_status: {
      type: String,
    },
  },
  async setup(props) {
    const HyTable = ref()
    // 获取直播间id
    // eslint-disable-next-line vue/no-setup-props-destructure
    let roomid = props.roomid
    onMounted(() => {
      return HyTable
    })
    const status = (param: any) => (param == 0 ? '已下架' : '已上架')
    //  索引
    const activeIndex = ref('1')
    const activeIndex2 = ref('1')
    // 显示table组件
    const addShopShow = () => {
      HyTable.value.changeVisibility(true)
    }
    const handleSelect = (key: any, keyPath: any) => {
      console.log(key, keyPath)
    }
    const handleSelect2 = (key: any, keyPath: any) => {
      console.log(key, keyPath)
    }

    // 默认页码
    const currentPage = ref(1)
    // 获取商品列表
    let tableData = ref()
    // 每页页数
    const pages = 10
    // 导入的商品列表
    const RoomProductList = await GetRoomProductList(
      currentPage.value - 1,
      pages,
      roomid,
      '',
    )
    tableData.value = RoomProductList.Data
    // 向上取整页码数
    let totalPage = Math.ceil(RoomProductList.Total / pages) * 100
    console.log(totalPage)

    const handleCurrentChange = async (val: any) => {
      console.log(val)
      currentPage.value = val
      let RoomProductList = await GetRoomProductList(
        currentPage.value - 1,
        pages,
        2,
        '',
      )
      tableData.value = RoomProductList.Data
    }

    // 确认添加
    async function myAddShop(GoodsIds: number[]) {
      const addRes = await AddGoods(GoodsIds, roomid)
      if (addRes.errcode == 0) {
        console.log(addRes)
        HyTable.value.changeVisibility(false)
      }
    }

    return {
      activeIndex,
      activeIndex2,
      tableData,
      tableItems,
      HyTable,
      // eslint-disable-next-line vue/no-dupe-keys
      roomid,
      status,
      handleSelect,
      handleSelect2,
      myAddShop,
      addShopShow,

      handleCurrentChange,
    }
  },
})
