
import { defineComponent, onMounted } from 'vue'
import { MainHeader } from '@/components/main-header/index'
import MenuActive from './cpns/el-menu-active.vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  components: { MainHeader, MenuActive },
  setup() {
    // 获取直播间id
    const route = useRoute()
    let roomid = route.query.roomid
    let live_status = route.query.live_status
    onMounted(() => {
      console.log(route.query.roomid)
    })
    return { roomid, live_status }
  },
})
