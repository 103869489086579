
import { defineComponent, ref } from 'vue'
import { ElMessageBox } from 'element-plus'
import { ElMessage } from 'element-plus'
import { RemoveInRoom, OnSale, GetRoomProductShop } from '@/service/main/main'
export default defineComponent({
  props: {
    roomid: {
      type: String,
    },
  },
  async setup(props) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    // 获取直播间id
    // eslint-disable-next-line vue/no-setup-props-destructure
    let roomid = props.roomid
    // 获取数据
    let shopList = ref()
    const pageSize = 5
    let currentPage = ref(1)
    let totalPage = ref(1)
    async function goShop(val: number) {
      const ShopRes = await GetRoomProductShop(roomid, '', pageSize, val - 1)
      shopList.value = ShopRes.Data
      totalPage.value = Math.ceil(ShopRes.Total / pageSize) * 100
      console.log(totalPage.value)
    }
    goShop(currentPage.value)

    const handleCurrentChange = (val: number) => {
      currentPage.value = val
      goShop(val)
    }

    const status = (param: any) => (param == 0 ? '已下架' : '已上架')
    // 删除直播间商品
    const handleDelete = (parameter: any) => {
      console.log(parameter.GoodsId)
      const { GoodsId } = parameter
      ElMessageBox.confirm('此操作将永久删除商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          RemoveInRoom(roomid, GoodsId)
          goShop(currentPage.value)
          ElMessage({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除',
          })
        })
    }
    // 直播间上下架商品
    const upShop = (parameter: any) => {
      const { GoodsId, Start } = parameter
      let onSale = 1
      if (Start == 0) {
        onSale = 1
      } else {
        onSale = 0
      }
      OnSale(roomid, GoodsId, onSale)
      // goShop()
    }

    return {
      handleDelete,
      status,
      upShop,
      handleCurrentChange,
      shopList,
      totalPage,
    }
  },
})
